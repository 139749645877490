<template>
    <div class="all">
        <AppTable
            primaryKey="id"
            :canCheck="canCheck"
            :canCheckAll="true"
            :columns="columnsShowed"
            :rows="tableData"
            :isProgressBar="isProgressBarLoading"
            :is-sticky-checkbox="true"
            :isStickyProgressBar="true"
            :rowsSelected="rowsSelected"
            :rowsSelectedPart="rowsSelectedPart"
            class="all-table"
            @lazyload="onLazyload"
            @sort="onSort"
            @search="onFilter"
            @onCheckRow="onCheckRow"
            @onCheckRowsAll="onCheckRowsAll"
        >
            <template
                v-for="headerColumn in columnsSlotHeader"
                v-slot:[headerColumn]
            >
                <TableSettings
                    :key="headerColumn"
                    :tableColumns="columns"
                    @onChangeTableColumns="changeTableColumns"
                />
            </template>
            <template
                v-for="(col,x) of [...modifieredCols, ...datesCols]"
                v-slot:[col]="scopeData"
            >
                <BaseCell
                    v-if="modifieredCols.includes(col)"
                    :key="x"
                    :content="scopeData.row[col]"
                    :short-content="baseCellContent(scopeData.row[col], scopeData.column.maxLength)"
                    :is-show-tooltip="showTooltip(col, scopeData.row[col], scopeData.column.maxLength)"
                />
                <div
                    v-if="datesCols.includes(col)"
                    :key="x"
                    class="cell-date-wrap"
                    v-tooltip="isColumnWithAlarm(col) ? getPricePartInfo({ row: scopeData.row, column: scopeData.column }) : ''"
                >
                    <label class="checkbox-label">
                        <div class="cell-checkbox">
                            <base-checkbox-v2
                                v-if="scopeData.row[col]"
                                :key="x"
                                :checked="getCheckedSubCheckbox({row: scopeData.row, column: scopeData.column})"
                            />
                        </div>
                        <div
                            class="cell-checkbox2"
                            :class="{'cell-checkbox2_active': getCheckedSubCheckbox({row: scopeData.row, column: scopeData.column})}"
                            :style="{
                                'border': getPriceBorder({ row: scopeData.row, column: scopeData.column }),
                                'border-color': isPriceNumber(scopeData.row[col]) ? getPriceColor({row: scopeData.row, column: scopeData.column}) : '',
                                'background': getCheckedSubCheckbox({row: scopeData.row, column: scopeData.column}) ? getPriceColor({row: scopeData.row, column: scopeData.column}) : '',
                                'color': getCheckedSubCheckbox({row: scopeData.row, column: scopeData.column}) ? '#000' : '#ADB0BB'
                            }"
                            @click="onCheckSubCheckbox({row: scopeData.row, column: scopeData.column, col, scopeData})"
                            @dblclick="onClickToEdit($event, col, scopeData.row.id, scopeData)"
                        >
                            <div v-if="typeof scopeData.row[col] === 'object'">
                                <div
                                    v-if="!isCellEditing(col, scopeData.row.id)"
                                    class="cell-checkbox2__cell"
                                >
                                    <IconAlarm
                                        v-if="isPricePart({ row: scopeData.row, column: scopeData.column }) && isColumnWithAlarm(col)"
                                        class="cell-checkbox2__price-part"
                                    />
                                    <span
                                        v-if="scopeData.column.prop !== 'offer_price' && scopeData.row[col].offer_price && scopeData.row[col].offer_price != scopeData.row[col].price && !isCellEditing(col, scopeData.row.id) && !isPriceBusy({row: scopeData.row, column: scopeData.column})"
                                    >
                                        <p style="text-decoration:line-through;font-size: 10px">
                                            {{ scopeData.row[col].price != '' && isNaN(scopeData.row[col].price) !== true ? parseInt(scopeData.row[col].price).toLocaleString('ru') : '' }}
                                        </p>
                                        <p :style="(scopeData.row[col].price < scopeData.row[col].offer_price) || !scopeData.row[col].price ? 'color:red' : 'color:green'">
                                            {{ scopeData.row[col].offer_price != '' && isNaN(scopeData.row[col].offer_price) !== true ? parseInt(scopeData.row[col].offer_price).toLocaleString('ru') : '' }}
                                        </p>
                                    </span>
                                    <span v-else-if="scopeData.column.prop === 'offer_price_month' && parseInt(scopeData.row[col].price) !== parseInt(getPricesSumAverage(scopeData.row))">
                                        <p style="text-decoration:line-through;font-size: 10px">
                                            {{ parseInt(getPricesSumAverage(scopeData.row)).toLocaleString('ru') }}
                                        </p>
                                        <p :style="{color: parseInt(scopeData.row[col].price) < parseInt(getPricesSumAverage(scopeData.row)) ? 'green' : 'red'}">
                                           {{ scopeData.row[col].price !== '' && isNaN(parseInt(scopeData.row[col].price)) !== true ? parseInt(scopeData.row[col].price).toLocaleString('ru') : '' }}
                                        </p>
                                    </span>
                                    <span v-else-if="scopeData.column.prop === 'offer_price' && parseInt(scopeData.row.offer_price_month.price) !== parseInt(getPricesSumAverage(scopeData.row))">
                                        <p :style="{color: parseInt(scopeData.row.offer_price_month.price) < parseInt(getPricesSumAverage(scopeData.row)) ? 'green' : 'red'}">
                                            {{ parseInt(scopeData.row.offer_price.price).toLocaleString('ru') }}
                                        </p>
                                    </span>
                                    <div
                                        v-else
                                        :style="{'text-decoration': isPriceBusy({row: scopeData.row, column: scopeData.column}) ? 'none' : 'none'}"
                                    >
                                        {{ scopeData.row[col].price !== '' && isNaN(parseInt(scopeData.row[col].price)) !== true ? parseInt(scopeData.row[col].price).toLocaleString('ru') : '' }}
                                    </div>
                                </div>
                                <!-- <div
                                    contenteditable
                                    v-show="isCellEditing(col, scopeData.row.id)"
                                    @keydown.enter="onFinishEdit($event, col, scopeData.row.id)"
                                    v-tooltip="getEditingValue(scopeData.row[col])"
                                    v-clipboard="() => getEditingValue(scopeData.row[col])"
                                    @blur="onBlur($event, col, scopeData.row)"
                                >
                                    {{getEditingValue(scopeData.row[col])}}
                                </div> -->
                                <div
                                    v-else
                                >
                                    <base-text-input
                                        ref="baseTextInput"
                                        :value="String(getEditingValue(scopeData.row[col]))"
                                        @onBlur="onBlurV2($event, col, scopeData.row)"
                                        @keydown.enter="onFinishEdit($event, col, scopeData.row.id)"
                                    />
                                </div>
                            </div>
                            <span v-else>
                                <!-- <img
                                    :style="{'width': '15px', 'heigth': '15px'}"
                                    src="@/assets/images/icons/hui.svg"
                                > -->
                                <div :style="{'width': '15px', 'heigth': '15px', 'background': '#dce1e8'}"/>
                                —
                            </span>
                        </div>
                    </label>
                </div>
            </template>

            <template v-slot:image_src="scopeData">
                <AppPhoto
                    :small="scopeData.row.photo.small"
                    :big="scopeData.row.photo.big"
                    class="all-table__photo"
                />
            </template>

            <template v-slot:light="scopeData">
                <AppLight :isLight="scopeData.row.light == 1"/>
            </template>

            <template v-slot:country_id="scopeData">
                <CountryFlag :abbreviation="scopeData.row.country_id"/>
            </template>

            <template v-slot:oohdesk_id="scopeData">
                <AppIdentifier :firstId="scopeData.row.ooh_marker_id" :secondId="scopeData.row.oohdesk_id"/>
            </template>

            <template v-slot:image_link="scopeData">
                <AppLink :link="scopeData.image_link"/>
            </template>

            <template v-slot:image_map="scopeData">
                <MapImage :lng="scopeData.row.lng" :lat="scopeData.row.lat"/>
            </template>

            <template v-slot:nds="scopeData">
                <base-checkbox-v2
                    v-if="getCheckedNds(scopeData) !== null"
                    :checked="getCheckedNds(scopeData)"
                    @change="onChangeNds(scopeData)"
                />
            </template>

            <template
                v-slot:period_date="scopeData"
            >
                <DatePickerDropdown
                    :isEdit="true"
                    :accessDates="getAccessDatesPrices({ row: scopeData.row, column: scopeData.column })"
                    :checkedDates="getDatesPrices({ row: scopeData.row, column: scopeData.column })"
                    @onApprove="onCheckDate($event, { row: scopeData.row, column: scopeData.column })"
                />
            </template>

            <template
                v-for="column in columnsDatepickerRange"
                v-slot:[column]="scopeData"
            >
                <BaseDatePickerRange
                    v-if="getIsDateRangePrice({ row: scopeData.row, column: scopeData.column })"
                    :key="column"
                    :minDate="getMinDatePrice({ row: scopeData.row, column: scopeData.column })"
                    :maxDate="getMaxDatePrice({ row: scopeData.row, column: scopeData.column })"
                    :isDisabled="getIsDisabledPeriodPrice({ row: scopeData.row, column: scopeData.column })"
                    :currentDateRange="getCurrentDateRangePrice({ row: scopeData.row, column: scopeData.column })"
                    @changeDatePicker="onChangeDatePicker($event, { row: scopeData.row, column: scopeData.column })"
                />
            </template>


            <template v-slot:more_btn>
                <div class="cell-freeze">
                    <BaseDropdown
                        class="cell-dropdown"
                        :padding="0"
                        :is-fixed="false"
                        position="right"
                        :style-list="dropdownStyles"
                        @changeVisible="onChangeVisibleDropdown"
                    >
                        <template #preview>
                            <BaseButtonIcon
                                class="table-dropdown-button cell-dropdown__prev-btn"
                                iconName="tabler-icon-dots-vertical"
                                tooltipText=""
                            />
                        </template>

                        <template #content v-if="isShowDropdown">
                            <div class="cell-dropdown__container">
                                <BaseButton
                                    view="simple"
                                    form="oval"
                                    size="small"
                                >Удалить
                                </BaseButton>
                            </div>
                        </template>
                    </BaseDropdown>
                </div>
            </template>
        </AppTable>
    </div>
</template>

<script>
import MixinHandbookTab from '@/mixins/MixinsHandbook/MixinHandbookTab';
import AppTable from '@/components/Table2/Table';
import AppPhoto from '@/components/CellComponents/Photo.vue';
import CountryFlag from '@/components/CellComponents/CountryFlag.vue';
import AppLight from '@/components/CellComponents/Light.vue';
import AppIdentifier from '@/components/CellComponents/Identifier.vue';
import AppLink from '@/components/CellComponents/Link.vue';
import BaseCell from '@/components/CellComponents/BaseCell.vue';
import MapImage from '@/components/CellComponents/MapImage.vue';
import BaseCheckboxV2 from '@/components/Base/BaseCheckboxV2';
import BaseTextInput from '@/components/Base/BaseTextInput';
import BaseDropdown from "@/components/Base/BaseDropdown";
import BaseButtonIcon from "@/components/Base/BaseButtonIcon";
import BaseButton from "@/components/Base/BaseButton";
import TableSettings from '../Table2/TableSettings.vue';
import IconAlarm from '../../components/Icons/IconAlarm.vue';
import DatePickerDropdown from '../../components/DatePickerDropdown.vue';
import BaseDatePickerRange from '../../components/Base/BaseDatePickerRange.vue';
import UtilDate from '../../utils/UtilDate.js';

export default {
    name: "KitBudgetTab",
    mixins: [MixinHandbookTab],
    components: {
        BaseButton,
        AppTable,
        AppPhoto,
        CountryFlag,
        AppLight,
        AppIdentifier,
        AppLink,
        BaseCell,
        MapImage,
        BaseCheckboxV2,
        BaseTextInput,
        BaseDropdown,
        BaseButtonIcon,
        TableSettings,
        IconAlarm,
        DatePickerDropdown,
        BaseDatePickerRange
    },
    /**
     * @property {Object} editingCell - координаты редактируемой ячейки
     * @property {Boolean} isShowDropdown - Показать скрыть dropdowm menu
     * @property {Object} dropdownStyles - Стили для dropdowm menu
     */
    data: () => ({
        editingCell: {},
        isShowDropdown: false,
        dropdownStyles: {
            minWidth: '100px',
            top: '0',
            right: '45px',
            boxShadow: '0 0 10px 0 #aaa',
        },
        clickTimeout: null,
        dblClickFlag: false
    }),
    props: {
        datesCols: {
            type: Array,
            default: () => ([])
        },
        rowsSelected: {
            type: Array,
            default: () => ([])
        },
        rowsCheckboxes: {
            type: Object,
            default: () => ({})
        },
        rowsSelectedPart: {
            type: Array,
            default: () => ([])
        },
        folderId: {
            type: [Number, String],
            required: true
        },
        canCheck: {
            type: Boolean,
            default: false
        },
        isProgressBarLoading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        columnsSlotHeader() {
            return this.columns.filter(column => Boolean(column?.isSlotHeader ?? false)).map(column => `header-${column?.prop ?? ''}`);
        },
        columnsShowed() {
            return this.columns.filter((column) => Boolean(column?.isShow ?? false));
        },
        columnsDatepickerRange() {
            return this.columns.filter(column => Boolean(column?.isDatepickerRange ?? false)).map(column => String(column?.prop ?? ''));
        }
    },
    // computed: {},
    methods: {
        onCheckDate(dates = [], slotData = {}) {
            this.$emit('onCheckDate', dates, slotData);
        },
        isColumnWithAlarm(columnKey = '') {
            const columnKeySplitted = String(columnKey).split('__')[1] ?? '';
            const isColumnWithAlarm = ['price', 'price_month'].includes(columnKeySplitted);

            return isColumnWithAlarm;
        },
        changeTableColumns(tableColumns = []) {
            this.$emit('onChangeTableColumns', tableColumns);
        },
        onCheckRow(checkData = {}) {
            this.$emit('onCheckRow', checkData);
        },
        onCheckRowsAll(isSelected = false) {
            this.$emit('onCheckRowsAll', isSelected);
        },
        onCheckSubCheckbox(checkData = {}) {
            // this.$emit('onCheckSubCheckbox', checkData);

            if (this.clickTimeout) {
                clearTimeout(this.clickTimeout);
                this.clickTimeout = null;
            }
            
            if (this.dblClickFlag) {
                this.dblClickFlag = false;
                return;
            }

            this.clickTimeout = setTimeout(() => {
                const isOfferPrice = checkData?.col.split('__').includes('offer_price');
                const cantEdit = this.getCantEdit(checkData?.col, checkData?.scopeData);

                if (!cantEdit || isOfferPrice)
                    this.$emit('onCheckSubCheckbox', checkData);

                this.clickTimeout = null;
            }, 300);
        },
        getCheckedSubCheckbox(checkData = {}) {
            const {column = {}, row = {}} = checkData;
            const {prop = ''} = column;
            const {id: rowId = -1} = row;
            const cellsChecked = this.rowsCheckboxes[rowId] || [];
            return cellsChecked.includes(String(prop).split('__')[0]);
        },
        getAccessDatesPrices(slotData = {}) {
            const prices = slotData?.row?.allPrices ?? [];
            const dates = prices.filter(price => String(price?.status ?? '') !== 'busy').map(price => UtilDate.getDatePickerObj(String(price?.date ?? '')));

            return dates;
        },
        getDatesPrices(slotData = {}) {
            const prices = slotData?.row?.prices ?? [];
            const dates = prices.map(price => UtilDate.getDatePickerObj(String(price?.date ?? '')));

            return dates;
        },
        getPriceBorder(tableData = {}) {
            const price = this.getPriceData(tableData);

            return price?.status ? '2px solid' : 'none';
        },
        getPriceData(slotData = {}) {
            // const propColumn = String(slotData?.column?.prop ?? '');
            // const prices = slotData?.row?.prices ?? [];
            // const date = propColumn.split('__')[0] ?? '';
            // const price = prices.find(price => String(price?.date ?? '') === date) ?? {};

            // return price;

            const {column = {}, row = {}} = slotData;
            const {allPrices = null, prices = []} = row;
            const {prop = ''} = column;
            const propSplitted = String(prop).split('__');
			const propDate = propSplitted[0];
            // const currentPrices = (allPrices === null || allPrices?.length === 0) ? Object.assign([], prices) : Object.assign([], allPrices);
            const currentPrices = allPrices.map(allPrice => {
                const price = prices.find(price => price?.id === allPrice?.id && price?.date === allPrice?.date) ?? {};
                const priceLinkSet = price?.priceLinkSet ?? {};
                const allPriceMapped = { ...allPrice, priceLinkSet };

                return allPriceMapped;
            });
            const price = currentPrices.find(price => String(price.date) === String(propDate)) || {};

            return price;
        },
        getPricesSum(slotData = {}) {
            const prices = slotData?.prices.map(item => item.price_month && isNaN(parseInt(item.price_month)) !== true ? parseInt(item.price_month) : 0) ?? [];

            const sum = prices.reduce((total, price) => total + price, 0);
            return sum;
        },
        getPricesSumAverage(slotData = {}) {
            const pricesLength = slotData?.prices.length ?? 0;
            const sum = this.getPricesSum(slotData);

            return sum / pricesLength;
        },
        getPeriodStatuses(slotData = {}) {
            const price = this.getPriceData(slotData);
            const periodStatuses = price?.periodStatuses ?? [];

            return periodStatuses;
        },
        getIsDateRangePrice(slotData = {}) {
            const price = this.getPriceData(slotData);

            return Object.keys(price).length > 0;
        },
        getMinDatePrice(slotData = {}) {
            const periodStatuses = this.getPeriodStatuses(slotData);
            const minDatePrice = periodStatuses[0]?.from_date ?? '';

            return minDatePrice;
        },
        getMaxDatePrice(slotData = {}) {
            const periodStatuses = this.getPeriodStatuses(slotData);
            const maxDatePrice = periodStatuses[periodStatuses.length - 1]?.to_date ?? '';

            return maxDatePrice;
        },
        getIsDisabledPeriodPrice(slotData = {}) {
            const periodStatuses = this.getPeriodStatuses(slotData);
            const currentRangePrice = this.getCurrentDateRangePrice(slotData);

            return periodStatuses.length === 0 || (currentRangePrice[0] === '' && currentRangePrice[1] === '');
        },
        getCurrentDateRangePrice(slotData = {}) {
            const price = this.getPriceData(slotData);
            const priceLinkSet = price?.priceLinkSet ?? {};
            const currentRangePrice = [String(priceLinkSet?.from_date ?? ''), String(priceLinkSet?.to_date ?? '')]

            return currentRangePrice;
        },
        async onChangeDatePicker(dateRange = [], slotData = {}) {
            const rowId = String(slotData?.row?.id ?? '');

            this.$emit('changePeriod', dateRange, rowId);
        },
        /**
         * Получить флаг для ндс
         * @param {Object} checkData - данные строки и выбора
         * @returns {Boolean|Null}
         */
        getCheckedNds(checkData = {}) {
            const {column = {}, row = {}} = checkData;
            const {prop = ''} = column;
            return typeof (row[prop]) !== 'undefined' ? row[prop] : null;
        },
        isPriceNumber(price = '') {
            return price !== '' && price !== null;
        },
        getCantEdit(col, scopeData = {}) {
            const date = String(col).split('__')[0] ?? '';
            const price = scopeData?.row?.allPrices?.find(price => String(price?.date ?? '') === date);

            const isNds = col.split('_').includes('nds');
            const isOfferPrice = col.split('__').includes('offer_price');
            const isBusy = String(price?.status ?? '') === 'busy';
            const isPriceWithoutDates = ['print_price', 'install_price', 'additional_install_price'].includes(String(col));
            const isCantEdit = ['period_price', 'offer_price'].includes(String(col)) || isOfferPrice;
            const isDigital = scopeData?.row?.isDigital ?? false;
            const isBusyAllPrices = scopeData?.row?.prices?.length === 0 || scopeData?.row?.prices?.every(price => String(price?.status ?? '') === 'busy');
            const cantEdit = isNds || isBusy || (isPriceWithoutDates && isDigital) || (isPriceWithoutDates && isBusyAllPrices) || isCantEdit;

            return cantEdit;
        },
        /**
         * Включаю возможность редактирования
         */
        onClickToEdit($event, col, row, scopeData = {}) {
            if (this.clickTimeout) {
                clearTimeout(this.clickTimeout);
                this.clickTimeout = null;
            }
            this.dblClickFlag = true;

            const cantEdit = this.getCantEdit(col, scopeData);

            if (cantEdit) return

            this.editingCell = {
                col: col,
                row: row
            };
            // console.log($event.target);
            $event.target.focus()
            // setTimeout(() => {
            //     $event.target.focus()
            // }, 0)
            this.$nextTick(() => {
                this.$refs.baseTextInput[0].onFocus();
            })
        },
        /**
         * Эмичу новое значение
         */
        onBlur($event, col, rowObj) {
            if (!this.isCellEditing(col, rowObj.id)) {
                return;
            }
            const content = $event.target.textContent;
            this.editingCell = {};
            this.changeValue({
                selected: [rowObj.id],
                dates: [col],
                changeValue: content,
                oldValue: this.getEditingValue(rowObj[col])
            })
        },
        onBlurV2(event, col, rowObj) {
            const content = event === null ? '' : event.target.value;
            if (!this.isCellEditing(col, rowObj.id)) {
                return;
            }
            this.editingCell = {};
            const changeAttributes = {
                'price': 'offer_price',
                'install_price': 'install_price',
                'print_price': 'print_price',
                'additional_install_price': 'additional_install_price',
                'offer_price_month': 'offer_price',
                'price_month': 'offer_price'
            };
            const colSplitted = String(col).split('__');

            const isColDates = !['print_price', 'install_price', 'additional_install_price'].includes(String(col));
            const dates = (isColDates && col !== 'offer_price_month') ? [colSplitted[0]] : rowObj?.prices.map(price => String(price?.date ?? ''));
            const changeAttribute = (isColDates && col !== 'offer_price_month') ? changeAttributes[colSplitted[1]] : changeAttributes[col];

            // eslint-disable-next-line no-debugger
            // debugger;

            this.changeValue({
                selected: [rowObj.id],
                // dates: [colSplitted[0]],
                dates,
                changeValue: content,
                // changeAttribute: changeAttributes[colSplitted[1]],
                changeAttribute,
                oldValue: this.getEditingValue(rowObj[col])
            })
        },
        /**
         * Эмитит измененное значение ячейки
         */
        changeValue(data) {
            data.changeValue = Number(data.changeValue.replace(/[^0-9]/g, ''));
            if (data.changeValue != data.oldValue) {
                this.$emit('changeSinglePrice', data);
            }
        },
        /**
         * Завершаю редактирование при нажатии на "enter"
         */
        onFinishEdit($event, col, row) {
            if (!this.isCellEditing(col, row)) {
                return;
            }
            $event.target.blur()
            this.editingCell = {};
        },
        isCellEditing(col, row) {
            let flag = Object.keys(this.editingCell).length !== 0 && this.editingCell.col == col && this.editingCell.row == row;
            return flag;
        },
        getEditingValue(row) {
            let value = row.offer_price ? row.offer_price : row.price;
            return isNaN(value) === true ? '' : value;
        },
        /**
         * Событие смены флага ндс
         * @param {Object} scopeData - данные строки и выбора
         */
        onChangeNds(scopeData = {}) {
            const {column = {}, row = {}} = scopeData;
            const {prop = ''} = column;
            const {id = '-1'} = row;
            const newValue = !row[prop];
            this.$emit('onChangeNds', id, newValue);
        },
        /**
         * Событие показать/скрыть дропдаун
         * @param {Boolean} newVal - флаг показать/скрыть
         */
        onChangeVisibleDropdown(newVal) {
            this.isShowDropdown = newVal;
        },
        /**
         * Метод показать тултип если текст не поместился в ячейку таблицы
         * @param {String} col - название ячейки
         * @param {String} val - текст ячейки
         * @param {Number} maxLength - максимальная длинна ячейки
         */
        showTooltip(col, val, maxLength = 999) {
            return (col === 'address' || col === 'gid') && (val.length > maxLength);
        },
        baseCellContent(content, maxLength = 0) {
            if (maxLength && (content.length > maxLength)) {
                const sliced = content.slice(0, maxLength);
                const result = `${sliced}...`;
                return result;
            }

            return content !== null ? String(content) : '';
        },
    },
    filters: {
        cutNumber(value = '') {
            if (value !== '') {
                const priceLocale = parseInt(value).toLocaleString('ru');
                const priceLocaleArr = String(priceLocale).split(/\s/);
                const priceFormatted = priceLocaleArr.length >= 2 ? `${priceLocaleArr[0]}${'к'.repeat(priceLocaleArr.length - 1)}` : `${priceLocaleArr[0]}`;
                return priceFormatted;
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.all {
    display: flex;

    &-table {
        margin-top: 4px;

        ::v-deep .header {
            .format-cell .cell__content span,
            .ots-cell .cell__content span,
            .grp-cell .cell__content span {
                text-align: right !important;
            }

            .cell.date-cell {
                padding: 4px 15px !important;
            }
        }

        ::v-deep .body {
            .cell.address-cell .default-cell,
            .cell.direction-cell .default-cell,
            .cell.material-cell .default-cell {
                height: inherit;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                white-space: normal;
            }

            .cell.address-cell .default-cell {
                word-break: break-all;
            }
        }

        &__photo {
            width: 34px;
            height: 34px;
        }
    }

    .cell-date-wrap {
        width: 100%;
        height: 100%;
    }


    .checkbox-label {
        .cell-checkbox {
            display: none !important;
        }

        .cell-checkbox2 {
            padding: 13px 15px;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: end;
            align-items: center;
            text-align: right;
            cursor: pointer;

            &__cell {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &__price-part {
                margin-right: 5px;
            }
        }
    }

    .cell-dropdown {
        ::v-deep .dropdown__triangle {
            top: 12px !important;
            right: -18px !important;
            border-left: 9px solid #fff !important;
            border-bottom-color: transparent !important;
            z-index: 6 !important;
        }

        ::v-deep .dropdown {
            box-shadow: 0 3px 15px #21242d17 !important;
        }

        &__container {
            position: relative;
            background: #fff;
            border-radius: 6px;
            overflow: hidden;
            z-index: 5;
            padding: 10px;

            button {
                padding: 5px 10px;
                font-weight: 500;
                color: #ADB0BB;
                transition: all .4s ease-out;

                &:hover {
                    color: #000;
                }
            }
        }
    }
}
</style>
