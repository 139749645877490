<template>
    <DatePicker
        v-model="dateRange"
        :language="ruLang"
        :range="true"
        :clearable="false"
        :disabled="isDisabled"
        :disabledDate="isDisabledDate"
        format="YYYY-MM-DD"
        @open="onOpenDatePicker"
        @change="onChangeDatePicker"
    />
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import ruLang from 'vue2-datepicker/locale/ru';

export default {
    name: 'BaseDatePickerRange',
    components: {
        DatePicker
    },
    props: {
        minDate: {
            type: String,
            default: ''
        },
        maxDate: {
            type: String,
            default: ''
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        currentDateRange: {
            type: Array,
            default: () => ([])
        }
    },
    data: () => ({
        dateRange: [],
        ruLang: ruLang
    }),
    computed: {
        minDateFormatted() {
            return this.toDateFormatted(this.minDate);
        },
        maxDateFormatted() {
            return this.toDateFormatted(this.maxDate);
        },
        dateRangeFormatted() {
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            const dateRangeFormatted = this.dateRange.map(date => date.toLocaleDateString('en-CA', options));

            return dateRangeFormatted;
        }
    },
    watch: {
        dateRange() {
            this.$emit('update:currentDateRange', this.dateRangeFormatted);
        },
        currentDateRange() {
            this.updateDateRange();
        }
    },
    mounted() {
        this.dateRange = this.currentDateRange.map(date => this.toDateFormatted(date));
        this.updateDateRange();
    },
    methods: {
        updateDateRange() {
            this.dateRange = this.currentDateRange.map(date => this.toDateFormatted(date));
        },
        isDisabledDate(date) {
            return date < this.minDateFormatted || date > this.maxDateFormatted;
        },
        toDateFormatted(date = '') {
            return new Date(`${date}T00:00:00`);
        },
        onOpenDatePicker() {
            this.$nextTick(() => {
                const datepickerPanelsDate = document.querySelectorAll('.mx-calendar.mx-calendar-panel-date');

                if (datepickerPanelsDate[1])
                    datepickerPanelsDate[1].style.display = 'none';
            });
        },
        onChangeDatePicker() {
            this.$emit('changeDatePicker', this.dateRangeFormatted);
        }
	}
}
</script>
